// import core functionality
import React from "react";

// import scss
import "../styles/Global.scss";
import "../styles/views/Home.scss";

// import  components
import HomeGrid from "../components/grids/HomeGrid";

export default function Home() {
  return (
    <div id="home">
      <HomeGrid />
    </div>
  );
}
