import React from "react";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/StyleGuide.scss";
import "../../../styles/views/auth/Auth.scss";

//import material ui components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function Styleguide() {
  return (
    <div className="styleguide">
      <Box className="Box" sx={{ boxShadow: 3 }} id="Typography">
        <Typography variant="h1" color="text.primary" className="title">
          Typography
        </Typography>
        <Typography variant="h1" color="primary">
          heading 1
        </Typography>
        <Typography variant="h2" color="primary">
          heading 2
        </Typography>
        <Typography variant="h3" color="primary">
          heading 3
        </Typography>
        <Typography variant="h4" color="primary">
          heading 4
        </Typography>
        <Typography variant="h5" color="primary">
          heading 5
        </Typography>
        <Typography variant="h6" color="primary">
          heading 6
        </Typography>
        <p className="spacer"></p>
        <Typography variant="b" color="text.primary">
          This is a sample text to showcase our bold text.
        </Typography>
        <Typography variant="i" className="italic" color="text.primary">
          This is a sample text to showcase our italic text.
        </Typography>
        <Typography variant="p" color="text.primary">
          This is a sample text to showcase our normal text.
        </Typography>
      </Box>
      <Box className="Box" sx={{ boxShadow: 3 }} id="Colors">
        <Typography variant="h1" color="text.primary" className="title">
          Color Palette
        </Typography>
        <Typography
          variant="h3"
          color="text.primary"
          bgcolor="background.primary"
          sx={{ boxShadow: 3 }}
        >
          primary (#26c485)
        </Typography>
        <Typography
          variant="h3"
          color="text.primary"
          bgcolor="background.secondary"
          sx={{ boxShadow: 3 }}
        >
          secondary (#16817e)
        </Typography>
        <Typography
          variant="h3"
          color="text.primary"
          bgcolor="background.paper"
          sx={{ boxShadow: 3 }}
        >
          background (#153243)
        </Typography>
        <Typography
          variant="h3"
          color="text.primary"
          bgcolor="background.default"
          sx={{ boxShadow: 3 }}
        >
          foreground (#284b63)
        </Typography>
        <Typography
          variant="h3"
          color="text.secondary"
          bgcolor="background.light"
          sx={{ boxShadow: 3 }}
        >
          text (#fefdff)
        </Typography>
      </Box>
      <Box className="Box" sx={{ boxShadow: 3 }} id="Buttons">
        <Typography variant="h1" color="text.primary" className="title">
          Buttons
        </Typography>
        <Stack direction="column" className="btns">
          <Button variant="contained" size="large">
            Primary Button
          </Button>
          <Button variant="contained" size="large" id="secondaryBtn">
            Secondary Button
          </Button>
        </Stack>
      </Box>
    </div>
  );
}
