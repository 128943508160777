import React from "react";
import { Player, BigPlayButton } from "video-react";

//import scss files
import "../../../../styles/Global.scss";
import "../../../../styles/views/miscellaneous/Media.scss";

//import material ui components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function VideoItem(props) {
  return (
    <Box sx={{ boxShadow: 3 }} className="Box" id="video">
      <div className="mediaContainer">
        <div className="mediaTop">
          <Player>
            <source src={props.video} />
            <BigPlayButton position="center" />
          </Player>
        </div>
        <div className="mediaBottom">
          <Typography variant="h1" color="text.primary">
            {props.title}
            <span id="positive">{props.pos1}</span>
            {props.pos2 != null && <span id="positive">{props.pos2}</span>}
            {props.pos3 != null && <span id="positive">{props.pos3}</span>}

            <span id="negative">{props.neg1}</span>
          </Typography>
          <p>{props.desc}</p>
        </div>
      </div>
    </Box>
  );
}
