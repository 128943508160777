import React from "react";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/About.scss";

//import material ui components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//import custom components
import AboutUsMemberContainerLeft from "../../../components/misc/AboutUsMemberContainerLeft";
import AboutUsMemberContainerRight from "../../../components/misc/AboutUsMemberContainerRight";
import imgMarvin from "../../../styles/assets/about/marvin.jpg";
import imgMichel from "../../../styles/assets/about/michel.jpg";

export default function About() {
  return (
    <div className="about">
      <Box sx={{ boxShadow: 3 }} className="Box" id="aboutContainer">
        <Typography variant="h1" color="primary" className="aboutHeading">
          About Us
        </Typography>
        <Typography color="text.primary" id="aboutText">
          Once again, the young, enthusiastic developer duo Michel Schrem &amp;
          Marvin Gabriel inspire with a groundbreaking project that they created
          from scratch in a time frame of only a few weeks for Module 152. One's
          broad experience with web technologies and the other's deep
          understanding of complex systems and functionalities complemented
          their skills once again and enabled an efficient implementation of
          their latest software solution. Even though the feedback from friends
          and relatives was always reassuringly positive, it remains to be seen
          whether these assessments will also be shared by the esteemed juror Mr
          von Orelli, himself a specialist in computer science at the Technical
          Vocational School in Zurich.
        </Typography>
      </Box>
      <div className="aboutMembers">
        <AboutUsMemberContainerLeft
          name="Marvin Gabriel"
          function="Design Wizard"
          desc="With his keen eye for detail, he implemented yet another stunning user interface that would make even Figma legionnaires doubt their abilities, when it comes to creating user interfaces."
          img={imgMarvin}
        />
        <AboutUsMemberContainerRight
          name="Michel Schrem"
          function="Technical Mastermind"
          desc="Through his enormous propensity for logical thinking, he enabled us to once again network our stunning user interface with a brain and thereby increase the interactivity with our platform immeasurably."
          img={imgMichel}
        />
      </div>
    </div>
  );
}
