import React from "react";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/Legal.scss";

// import material ui components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Imprint() {
  return (
    <div className="legalWrapper">
      <Box sx={{ boxShadow: 3 }} className="Box">
        <div className="legalSection">
          <Typography variant="h1" color="primary" className="legalHeading">
            Imprint
          </Typography>
          <Typography color="text.primary" id="legalExample">
            <ul>
              <li>
                <span>MarMic Foundation</span>
              </li>
              <li>
                <span>Ausstellungsstrasse 70</span>
              </li>
              <li>
                <span>8004 Zürich</span>
              </li>
              <li>
                <span>Schweiz</span>
              </li>
              <li>
                <span>info@marmic.xyz</span>
              </li>
            </ul>
          </Typography>
        </div>
        <div className="legalSection">
          <Typography variant="h2" className="legalHeading" textAlign="left">
            Exclusion of liability
          </Typography>
          <Typography color="text.primary">
            The author assumes no responsibility for the correctness, accuracy,
            timeliness, reliability and completeness of the information.
            Liability claims regarding damage caused by the use of any
            information provided, including any kind of information which is
            incomplete or incorrect, will therefore be rejected. All offers are
            non-binding. The author expressly reserves the right to change,
            supplement or delete parts of the pages or the entire offer without
            prior notice or to discontinue publication temporarily or
            permanently.
          </Typography>
        </div>
        <div className="legalSection" id="legalEnd">
          <Typography variant="h2" className="legalHeading">
            Liability regarding links
          </Typography>
          <Typography color="text.primary" textAlign="left">
            References and links to third party websites are outside our area of
            responsibility. Any responsibility for such websites is declined.
            Access and use of such websites is at the user's own risk.
          </Typography>
        </div>
      </Box>
    </div>
  );
}
