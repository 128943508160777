import React from "react";

//import scss files
import "../../../../styles/Global.scss";
import "../../../../styles/views/miscellaneous/Media.scss";

//import material ui components
import VideoItem from "./VideoItem";

// import video
import videoMP4 from "../../../../styles/assets/media/video.mp4";
import videoMOV from "../../../../styles/assets/media/video.mov";
import videoAVI from "../../../../styles/assets/media/video.avi";

export default function Videos() {
  return (
    <div className="media">
      <VideoItem
        video={videoMP4}
        title=".MP4"
        pos1="POPULARITY"
        pos2="QUALITY"
        pos3="COMPRESSION"
        neg1="ENCODING/DECODING"
        desc="MP4 (Also known as MPEG-4) is the most spread video format in the modern internet. It is a good format for storing images and audio files."
      />

      <VideoItem
        video={videoMOV}
        title=".MOV"
        pos1="POPULARITY"
        neg1="SUPPORT ON MOST DEVICES"
        desc="The MOV format was developed with Apple's QuickTime architecture. It is primarily used for editing because not much is compressed."
      />

      <VideoItem
        video={videoAVI}
        title=".AVI"
        pos1="IMAGE QUALITY"
        pos2="SUPPORT"
        neg1="FLEXIBILITY"
        desc="Audio Video Interleave (AVI) is Microsoft's answer on Apple's QuickTime format."
      />
    </div>
  );
}
