// import core functionality
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// import views
import Home from "./views/Home";
import Login from "./views/auth/Login";
import Register from "./views/auth/Register";
import About from "./views/miscellaneous/Concept/About";
import Imprint from "./views/miscellaneous/legal/Imprint";
import Styleguide from "./views/miscellaneous/Concept/Styleguide";
import Testing from "./views/miscellaneous/Concept/Testing";
import Schedule from "./views/miscellaneous/Schedule/Schedule";
import Mockups from "./views/miscellaneous/Mockups/Mockups";
import Gdpr from "./views/miscellaneous/legal/Gdpr";
import Licenses from "./views/miscellaneous/legal/Licenses";
import Images from "./views/miscellaneous/Media/Images/Images";
import Videos from "./views/miscellaneous/Media/Videos/Videos";
import Audio from "./views/miscellaneous/Media/Audio/Audio";

// import material ui components
import Box from "@mui/material/Box";

// import scss
import "./styles/Global.scss";
import "./styles/components/misc/Background.scss";

// import components / animations
import Header from "./components/header/Header";
import FadeInAnimation from "./animations/FadeInAnimation";
import Background from "./components/misc/Background";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#26c485",
    },
    secondary: {
      main: "#16817e",
    },
    background: {
      default: "#153243",
      paper: "#284b63",
      primary: "#26c485",
      secondary: "#16817e",
      light: "#fefdff",
    },
    text: {
      primary: "#fefdff",
      secondary: "#153243",
    },
  },
  typography: {
    h1: {
      fontWeight: "bold",
      fontSize: "xx-large",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "x-large",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "large",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "medium",
    },
    h5: {
      fontWeight: "bold",
      fontSize: "small",
    },
    h6: {
      fontWeight: "bold",
      fontSize: "x-small",
    },
    b: {
      fontWeight: "bold",
      fontSize: "xx-small",
    },
    i: {
      fontWeight: "lighter",
      fontSize: "xx-small",
    },
    p: {
      fontWeight: "lighter",
      fontSize: "xx-small",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Router>
          <Header />
          <FadeInAnimation>
            <Box id="Content">
              <div className="background">
                <Background />
                <Routes>
                  <Route path="/" element={<Home />} />

                  <Route exact path="/about" element={<About />} />
                  <Route exact path="/schedule" element={<Schedule />} />
                  <Route exact path="/mockups" element={<Mockups />} />
                  <Route exact path="/styleguide" element={<Styleguide />} />
                  <Route exact path="/testing" element={<Testing />} />

                  <Route exact path="/images" element={<Images />} />
                  <Route exact path="/videos" element={<Videos />} />
                  <Route exact path="/audio" element={<Audio />} />

                  <Route exact path="/register" element={<Register />} />
                  <Route exact path="/login" element={<Login />} />

                  <Route exact path="/imprint" element={<Imprint />} />
                  <Route exact path="/gdpr" element={<Gdpr />} />
                  <Route exact path="/licenses" element={<Licenses />} />
                </Routes>
              </div>
            </Box>
          </FadeInAnimation>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;
