import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/Schedule.scss";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@mui/icons-material/School";

export default function ScheduleItem(props) {
  return (
    <VerticalTimelineElement
      id="scheduleElement"
      contentStyle={{ background: "#fff", color: "#284b63" }}
      contentArrowStyle={{ borderRight: "14px solid  #26c485" }}
      date={props.date}
      iconStyle={{ background: "#26c485", color: "#fefdff" }}
      icon={<SchoolIcon />}
    >
      <h3>{props.title}</h3>
      <h4>{props.subtitle}</h4>
      <p>{props.text}</p>
    </VerticalTimelineElement>
  );
}
