import React from "react";

//import scss files
import "../../../../styles/Global.scss";
import "../../../../styles/views/miscellaneous/Media.scss";

//import material ui components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function ImageItem(props) {
  return (
    <Box sx={{ boxShadow: 3 }} className="Box" id="image">
      <div className="mediaContainer">
        <div className="mediaTop">
          <img src={props.img} height="512" alt={props.title} />
        </div>
        <div className="mediaBottom">
          <Typography variant="h1" color="text.primary">
            {props.title}
            <span id="positive">{props.pos1}</span>
            <span id="positive">{props.pos2}</span>
            <span id="positive">{props.pos3}</span>
            <span id="negative">{props.neg1}</span>
          </Typography>
          <p>{props.desc}</p>
        </div>
      </div>
    </Box>
  );
}
