import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import ScheduleItem from "./ScheduleItem";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/Mockups.scss";

//import material ui components
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

export default function Schedule() {
  return (
    <div className="schedule">
      <Box sx={{ boxShadow: 3 }} className="Box">
        <VerticalTimeline id="scheduleTimeline">
          <ScheduleItem
            date="20.05.2022 - 27.05.2022"
            title="Infrastructure"
            subtitle="Webspace &amp; Domain Setup"
            text="We booked our own web space, including a suitable domain (marmic.xyz) with the German provider ZapHosting. We spent the morning getting the webspace up and running and setting up a NodeJS deployment environment."
          />
          <ScheduleItem
            date="28.05.2022 - 03.06.2022"
            title="Concept Phase"
            subtitle="Project Idea &amp; Wireframes "
            text="On the second Friday of our project work we conceptualised our project idea. In addition, we started designing the first mockups."
          />

          <ScheduleItem
            date="04.06.2022 - 10.06.2022"
            title="Homepage"
            subtitle="Conecpt &amp; Schedule"
            text="We started working on the homepage of our project. We undertook the task of creating a concept. We also undertook the task of creating a wireframe for the homepage."
          />

          <ScheduleItem
            date="11.06.2022 - 17.06.2022"
            title="Photo Shooting"
            subtitle="@Work"
            text="On this friday, we went into the woods near or working place and shot a couple of photos for the project."
          />

          <ScheduleItem
            date="18.06.2022 - 24.06.2022"
            title="Development Tryhard-Session"
            subtitle="Media &amp; Animations"
            text="We added audio, video and a image showcase, containing the media from the photo-shooting. we also added a background-animation to the homepage."
          />

          <ScheduleItem
            date="25.06.2022 - 01.07.2022"
            title="Responsiveness"
            subtitle="Responsive Design &amp; Legal"
            text="Our website got the premium treatment of being responsive. We also added a legal notice to the homepage."
          />

          <ScheduleItem
            date="02.07.2022 - 07.07.2022"
            title="Crunch Time"
            subtitle="Last Touches &amp; Deployment"
            text="The final hours of our work were mainly focused on optimising the product. Finally, we switched on the application on the web server that we had rented on our first module day."
          />
          <VerticalTimelineElement
            iconStyle={{ background: "#16817e", color: "#fefdff" }}
            icon={<StarIcon />}
          />
        </VerticalTimeline>
      </Box>
    </div>
  );
}
