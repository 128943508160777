import React from "react";
import ImageSlider from "react-image-comparison-slider";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/Mockups.scss";

//import material ui components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function MockupItem(props) {
  return (
    <Box sx={{ boxShadow: 3 }} className="Box">
      <div className="mockupContainer">
        <div className="mockupTop">
          <Typography variant="h1" color="primary">
            {props.title}
          </Typography>
        </div>
        <div className="mockupBottom">
          <ImageSlider
            image1={props.img1}
            image2={props.img2}
            sliderWidth={3}
            sliderColor="#26c485"
            handleColor="#26c485"
            handleBackgroundColor="white"
          />
        </div>
      </div>
    </Box>
  );
}
