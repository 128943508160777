import React from "react";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/About.scss";
import "../../../styles/views/miscellaneous/Legal.scss";

//import material ui components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Gdpr() {
  return (
    <div className="legalWrapper">
      <Box sx={{ boxShadow: 3 }} className="Box">
        <div className="legalSection">
          <Typography variant="h1" color="primary" className="legalHeading">
            GDPR
          </Typography>
          <Typography color="text.primary">
            The General Data Protection Regulation (GDPR) is a law that
            regulates the handling of personal data. This law has been in force
            since the May 25, 2018 in the member states of the EU and further in
            Europe.
          </Typography>
        </div>
        <div className="legalSection">
          <Typography variant="h2" className="legalHeading" textAlign="left">
            Regulatory purpose
          </Typography>
          <Typography color="text.primary">
            It regulates that personal data is only collected or passed on if
            the data subject consents. data subject also consents. In addition,
            everyone has the right to know which personal data is stored by a
            company. Furthermore, persons can demand the request deletion of
            their personal data, which companies must comply with, provided that
            the storage of this data is not required by other legal regulations.
          </Typography>
        </div>
        <div className="legalSection" id="legalEnd">
          <Typography variant="h2" className="legalHeading">
            Example of personal data
          </Typography>

          <Typography color="text.primary" textAlign="left" id="legalExmaples">
            <ul>
              <li>
                <span>Name</span>
              </li>
              <li>
                <span>Date of birth / Age</span>
              </li>
              <li>
                <span>Address</span>
              </li>
              <li>
                <span>Phone number</span>
              </li>
              <li>
                <span>E-mail address</span>
              </li>
              <li>
                <span>Account or credit card number</span>
              </li>
              <li>
                <span>Identity card number</span>
              </li>
              <li>
                <span>Vehicle number</span>
              </li>
              <li>
                <span>Medical data</span>
              </li>
              <li>
                <span>Biometric data</span>
              </li>
              <li>
                <span>IP address</span>
              </li>
            </ul>
          </Typography>
        </div>
      </Box>
    </div>
  );
}
