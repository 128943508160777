import React from "react";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/Mockups.scss";

//import material ui components
import MockupItem from "./MockupItem";
import img1 from "../../../styles/assets/bild1.png";
import img2 from "../../../styles/assets/bild2.png";

import audio from "../../../styles/assets/mockups/Audio.jpg"
import audio_now from "../../../styles/assets/mockups/Audio_jetzt.jpg"

import video from "../../../styles/assets/mockups/Videos.jpg"
import video_now from "../../../styles/assets/mockups/Videos_jetzt.jpg"

import images from "../../../styles/assets/mockups/Images.jpg"
import images_now from "../../../styles/assets/mockups/Images_jetzt.jpg"

import home from "../../../styles/assets/mockups/Home.jpg"
import home_now from "../../../styles/assets/mockups/Home_jetzt.jpg"

import legal from "../../../styles/assets/mockups/Legal.jpg"
import legal_now from "../../../styles/assets/mockups/Legal_jetzt.jpg"

import mockups from "../../../styles/assets/mockups/Mockups.jpg"
import mockups_now from "../../../styles/assets/mockups/Mockups_jetzt.jpg"

import schedule from "../../../styles/assets/mockups/Schedule.jpg"
import schedule_now from "../../../styles/assets/mockups/Schedule_jetzt.jpg"

import styleguide from "../../../styles/assets/mockups/Styleguide.jpg"
import styleguide_now from "../../../styles/assets/mockups/Styleguide_jetzt.jpg"




export default function Mockups() {
  return (
    <div className="mockups">
      <MockupItem
        title="Home"
        img1={home}
        img2={home_now}
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates temporibus, quo, obcaecati alias veritatis quae cupiditate ratione cum quas repellendus odio illum illo minus."
      />

      <MockupItem
        title="About Us"
        img1={img1}
        img2={img2}
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates temporibus, quo, obcaecati alias veritatis quae cupiditate ratione cum quas repellendus odio illum illo minus."
      />

      <MockupItem
        title="Style Guide"
        img1={styleguide}
        img2={styleguide_now}
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates temporibus, quo, obcaecati alias veritatis quae cupiditate ratione cum quas repellendus odio illum illo minus."
      />

      <MockupItem
        title="Mockups"
        img1={mockups}
        img2={mockups_now}
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates temporibus, quo, obcaecati alias veritatis quae cupiditate ratione cum quas repellendus odio illum illo minus."
      />

      <MockupItem
        title="Schedule"
        img1={schedule}
        img2={schedule_now}
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates temporibus, quo, obcaecati alias veritatis quae cupiditate ratione cum quas repellendus odio illum illo minus."
      />

      <MockupItem
        title="Images"
        img1={images}
        img2={images_now}
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates temporibus, quo, obcaecati alias veritatis quae cupiditate ratione cum quas repellendus odio illum illo minus."
      />

      <MockupItem
        title="Videos"
        img1={video}
        img2={video_now}
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates temporibus, quo, obcaecati alias veritatis quae cupiditate ratione cum quas repellendus odio illum illo minus."
      />

      <MockupItem
        title="Audio"
        img1={audio}
        img2={audio_now}
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates temporibus, quo, obcaecati alias veritatis quae cupiditate ratione cum quas repellendus odio illum illo minus."
      />

      <MockupItem
        title="Legal"
        img1={legal}
        img2={legal_now}
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates temporibus, quo, obcaecati alias veritatis quae cupiditate ratione cum quas repellendus odio illum illo minus."
      />
    </div>
  );
}
