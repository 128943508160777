import React from "react";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/Testing.scss";

//import material ui components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";


function createData(protocol, description, status) {
    return {protocol, description, status};
}

const rows = [
    createData("Loading times (Lighthouse)", "Does the site load at acceptable times, without major loading issues?", "good"),
    createData("Animations in the background", "Does our animations in the background work?", "good"),
    createData("Transitions between sites", "Can we see a small transition, when going between pages?", "bad"),
    createData("Responsive site", "Are our pages responsive?", "good"),
    createData("Navigation", "Are all navigation options bound and working?", "good"),
]

export default function Testing() {
    return (
        <div className="testing">
            <Box sx={{boxShadow: 3}} className="Box">
                <Typography variant="h1" color="primary">
                    Testing
                </Typography>
                {/*Table for testing protocols*/}
                <TableContainer className="tableStandard" sx={{minWidth: 650}} component={Paper}>
                    <Table>
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell>PROTOCOL</TableCell>
                                <TableCell>DESCRIPTION</TableCell>
                                <TableCell>STATUS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => (
                                <TableRow
                                    key={row.protocol}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>{row.protocol}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
}
