import React from "react";
import "../../styles/components/misc/Background.scss";

export default function Background() {

    var lists = [];
    let numrows = 30;

    for (var i = 0; i < numrows; i++) {
        lists.push("<li></li>");
    }

    return (
    <ul id="ulList">
        {lists.map(function(item, index) {
            return <li key={index}></li>;
        }
        )}
    </ul>
  );
}
