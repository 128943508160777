import React from "react";

//import scss files
import "../../../styles/Global.scss";
import "../../../styles/views/miscellaneous/About.scss";
import "../../../styles/views/miscellaneous/Legal.scss";

//import material ui components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Licenses() {
  return (
    <div className="legalWrapper">
      <Box sx={{ boxShadow: 3 }} className="Box">
        <div className="legalSection">
          <Typography variant="h1" color="primary" className="legalHeading">
            Licenses
          </Typography>
          <Typography color="text.primary" textAlign="left">
            The copyrights and all other rights to the content, images, photos
            or other files on the website belong exclusively to the marmic
            foundation or to the specifically named copyright holders. For the
            reproduction of any elements, the written consent of the copyright
            holders must be obtained in advance.
          </Typography>
        </div>
        <div className="legalSection">
          <Typography variant="h2" className="legalHeading" textAlign="left">
            Public Domain
          </Typography>
          <Typography color="text.primary">
            Works in the public domain have no author. This means that you can
            use them without a licence. Works in the public domain are:
            <ul>
              <li>
                <span>
                  Works whose copyright term has expired - Works that are
                  excluded from copyright protection by law.
                </span>
              </li>
              <li>
                <span>
                  Works that are excluded from copyright protection by law.
                </span>
              </li>
            </ul>
            Copyright protection for ordinary works expires 70 years after the
            death of the author, for computer programs it is 50 years. Works
            that are exempt from copyright protection For example, scientific
            data (e.g. population figures) Works in the public domain can be
            copied, edited and distributed as desired.
          </Typography>
        </div>
        <div className="legalSection" id="legalEnd">
          <Typography variant="h2" className="legalHeading">
            Fair Use
          </Typography>
          <Typography color="text.primary">
            Works that are protected by copyright may be copied if the content
            is used for a specific purpose. "Fair use occurs, for example, when:
            <ul>
              <li>
                <span>criticism or comment is made about a work</span>
              </li>
              <li>
                <span>a work is parodied</span>
              </li>
            </ul>
            For example, fair use allows a teacher to comment on an excerpt from
            a newspaper article without infringing copyright.
          </Typography>
        </div>
      </Box>
    </div>
  );
}
