// import react functionality
import React from "react";
import { Link } from "react-router-dom";

// imports scss files
import "../../styles/components/header/Header.scss";
import "../../styles/Global.scss";

// import MUI components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

export default function Header() {
  return (
    <>
      <Box sx={{ flexGrow: 1, boxShadow: 3 }}>
        <AppBar position="sticky" color="common" className="Header">
          <Toolbar>
            <Typography
              variant="h1"
              fontWeight="bold"
              component="div"
              color="primary"
              className="headerLogo"
              href="/"
              sx={{ flexGrow: 1 }}
            >
              <Link to={"/"}>
                <h5>marmic.xyz</h5>
              </Link>
            </Typography>
            <Stack
              direction="row"
              spacing={{ xs: 0, sm: 0, md: 2 }}
              id="btnStack"
            >
              <Button
                variant="link"
                component={Link}
                to={"/"}
                className="headerLink"
              >
                Home
              </Button>

              <PopupState variant="popover" popupId="conceptDropdown">
                {(popupState) => (
                  <React.Fragment>
                    <Button variant="link" {...bindTrigger(popupState)}>
                      Concept
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        component={Link}
                        to={"/about"}
                        onClick={popupState.close}
                      >
                        About Us
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={"/schedule"}
                        onClick={popupState.close}
                      >
                        Schedule
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={"/mockups"}
                        onClick={popupState.close}
                      >
                        Mockups
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={"/styleguide"}
                        onClick={popupState.close}
                      >
                        Styleguide
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={"/testing"}
                        onClick={popupState.close}
                      >
                        Testing
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>

              <PopupState variant="popover" popupId="mediaDropdown">
                {(popupState) => (
                  <React.Fragment>
                    <Button variant="link" {...bindTrigger(popupState)}>
                      Media
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        component={Link}
                        to={"/images"}
                        onClick={popupState.close}
                      >
                        Images
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={"/videos"}
                        onClick={popupState.close}
                      >
                        Videos
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={"/audio"}
                        onClick={popupState.close}
                      >
                        Audio
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>

              {/* -------------- */
              /* Legal dropdown */
              /* -------------- */}
              <PopupState variant="popover" popupId="copyrightDropdown">
                {(popupState) => (
                  <React.Fragment>
                    <Button variant="link" {...bindTrigger(popupState)}>
                      Legal
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        component={Link}
                        to={"/imprint"}
                        onClick={popupState.close}
                      >
                        Imprint
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={"/licenses"}
                        onClick={popupState.close}
                      >
                        Licenses
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={"/gdpr"}
                        onClick={popupState.close}
                      >
                        GDPR
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
