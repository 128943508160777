import React from "react";

//import scss files
import "../../../../styles/Global.scss";
import "../../../../styles/views/miscellaneous/Media.scss";

//import material ui components
import AudioItem from "./AudioItem";

// import audio
import audioMP3 from "../../../../styles/assets/media/Sound.mp3";
import audioWAV from "../../../../styles/assets/media/Sound.wav";
import audioAAC from "../../../../styles/assets/media/Sound.m4a";

export default function Audio() {
  return (
    <div className="media" id="audio">
      <AudioItem
        audio={audioMP3}
        title=".MP3"
        pos1="GOOD QUALITY"
        pos2="SMALL FILE SIZE"
        neg1="COMPRESSION"
        desc="MP3 is one of the most widely used formats of audio files. There is good compression, which means that the storage requirement is very low. The quality is good and it is supported by almost all players."
      />

      <AudioItem
        audio={audioWAV}
        title=".WAV"
        pos1="GOOD QUALITY"
        pos2="FLEXIBILITY"
        neg1="FILE SIZE"
        desc="WAV audio is mostly used for non-compressed audio formats, but can also contain compressed audio. Mostly, WAV files are therefore relatively large and are used when no loss of quality is tolerated."
      />

      <AudioItem
        audio={audioAAC}
        title=".AAC"
        pos1="QUALITY"
        pos2="COMPATIBILITY"
        neg1="ENCODING"
        desc="The AAC format was developed as a successor to the MP3 format. The compression is better than that of the MP3 format and the audio quality is higher."
      />
    </div>
  );
}
