import React from "react";
import ReactAudioPlayer from "react-audio-player";

//import scss files
import "../../../../styles/Global.scss";
import "../../../../styles/views/miscellaneous/Media.scss";

//import material ui components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function AudioItem(props) {
  return (
    <Box sx={{ boxShadow: 3 }} className="Box">
      <div className="mediaContainer">
        <div className="mediaTop">
          <ReactAudioPlayer src={props.audio} id="audioPlayer" controls />
        </div>
        <div className="mediaBottom">
          <Typography variant="h1" color="text.primary">
            {props.title}
          </Typography>
          <div className="features">
            <span id="positive">{props.pos1}</span>
            <span id="positive">{props.pos2}</span>
            <span id="negative">{props.neg1}</span>
          </div>
          <p>{props.desc}</p>
        </div>
      </div>
    </Box>
  );
}
