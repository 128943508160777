import React from "react";
import { Link } from "react-router-dom";

// import scss
import "../../styles/components/grids/Grid.scss";

// import components
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

export default function HomeGridTile(props) {
  return (
    <div>
      <Link to={props.link}>
        <Grid item sx={{ boxShadow: 3 }} className="gridItem">
          <img src={props.img} alt={props.title}></img>
          <Typography variant="h1" align="center">
            {props.title}
          </Typography>
        </Grid>
      </Link>
    </div>
  );
}
