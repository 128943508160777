import React from "react";

// import scss
import "../../styles/components/grids/Grid.scss";

// import components
import Grid from "@mui/material/Grid";
import HomeGridTile from "./HomeGridTile";

export default function HomeGrid() {
  return (
    <>
      <Grid container direction="row" justifyContent="center" className="grid">
        <HomeGridTile
          title="About Us"
          link="/about"
          img="https://www.susangreenecopywriter.com/wp-content/uploads/2013/01/pexels-photo-3184465.jpeg"
        />
        <HomeGridTile
          title="Schedule"
          link="/schedule"
          img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGe5K34iCAd9udVHs2uAkcK4C6gFT3b2p1OkyrQqdCKPwxrVuQQnv55qyPvxzvIRgz51c&usqp=CAU"
        />
        <HomeGridTile
          title="Style Guide"
          link="/styleguide"
          img="https://cdn.prod.www.spiegel.de/images/1ea1792c-0001-0004-0000-000000915608_w948_r1.778_fpx29.37_fpy45.jpg"
        />
        <HomeGridTile
          title="Mockups"
          link="/mockups"
          img="https://www.greenliff.swiss/sites/default/files/styles/8_column/public/2020-02/software-blueprint-workshop.jpg?itok=Y680bbpp"
        />
        <HomeGridTile
          title="Testing"
          link="/testing"
          img="https://neilpatel.com/wp-content/uploads/2014/11/abtesting.jpg"
        />
        <HomeGridTile
          title="Images"
          link="/images"
          img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxV2bIGov7Ud3JWOq8NkEp1lX0poaJlpEAeQ&usqp=CAU"
        />
        <HomeGridTile
          title="Videos"
          link="/videos"
          img="https://pbblogassets.s3.amazonaws.com/uploads/2017/08/27130641/Photography-Cover.jpg"
        />
        <HomeGridTile
          title="Audio"
          link="/audio"
          img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrtIgkYUAU9zt-JFrjHwgX7GSjmzXFg6fIPg&usqp=CAU"
        />
      </Grid>
    </>
  );
}
