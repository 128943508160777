// import core functionality
import React, { useState } from "react";
import Axios from "axios";

// import material ui components
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

// import scss
import "../../styles/Global.scss";
import "../../styles/views/auth/Auth.scss";
import { Typography } from "@mui/material";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState({}); // stores alert message

  Axios.defaults.withCredentials = true;

  const handleSubmit = (e) => {
    e.preventDefault();

    Axios.post("http://localhost:3500/login/", {
      username: username,
      password: password,
    }).then((response) => {
      if (response.data.auth === true) {
        setMessage({
          name: "success",
          message: "Login Erfolgreich.",
          severity: "success",
        });
        localStorage.setItem("token", response.data.token);
        setTimeout(function () {
          window.location.href = "/managment";
        }, 500);
      } else {
        setMessage({
          name: "error",
          message: response.data.message,
          severity: "error",
        });
      }
    });
  };

  // Generate JSX code for error message
  const renderMessage = (name) =>
    name === message.name && (
      <Alert className="message" severity={message.severity}>
        <b>{message.message}</b>
      </Alert>
    );

  return (
    <Box sx={{ flexGrow: 1, boxShadow: 3 }} className="Box" id="Auth">
      <form className="form" onSubmit={handleSubmit}>
        <Typography variant="h1" align="center">
          Anmelden
        </Typography>
        <Stack spacing={3} id="form">
          {renderMessage("success")}
          {renderMessage("error")}
          <TextField
            required
            label="Benutzername"
            variant="standard"
            type="text"
            sx={{
              "& .MuiInput-underline:before": { borderBottomColor: "#fefdff" },
              "& .MuiInput-underline:hover": { borderColor: "red" },
              "& .MuiInput-underline:after": { borderBottomColor: "#26c485" },
            }}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            fullWidth
          />
          <TextField
            required
            label="Kennwort"
            variant="standard"
            type="password"
            sx={{
              "& .MuiInput-underline:before": { borderBottomColor: "#fefdff" },
              "& .MuiInput-underline:after": { borderBottomColor: "#26c485" },
            }}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            fullWidth
          />
        </Stack>
        <Stack direction="row" className="btns">
          <Button
            variant="contained"
            size="large"
            id="secondaryBtn"
            href="/register"
          >
            Konto Anlegen
          </Button>
          <Button variant="contained" size="large" type="submit">
            Anmelden
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
