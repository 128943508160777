// import react functionality
import React from "react";

// imports scss files
import "../../styles/Global.scss";
import "../../styles/components/misc/AboutUs.scss";

// import MUI components

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function AboutUsMemberContainerLeft(props) {
  return (
    <div>
      <Box sx={{ flexGrow: 1, boxShadow: 3 }} className="Box">
        <div className="aboutRow">
          <div className="aboutElement" id="left">
            <Typography variant="h2" color="primary" className="aboutHeading">
              <span>{props.function}</span>
              {props.name}
            </Typography>
            <Typography>{props.desc}</Typography>
          </div>
          <div className="aboutElement" id="left">
            <img src={props.img} alt="Marvin Gabriel"></img>
          </div>
        </div>
      </Box>
    </div>
  );
}
