import React from "react";

//import scss files
import "../../../../styles/Global.scss";
import "../../../../styles/views/miscellaneous/Media.scss";

//import material ui components
import ImageItem from "./ImageItem";

// import image
import imageJPEG from "../../../../styles/assets/media/image_jpg.jpg";
import imagePNG from "../../../../styles/assets/media/image_png.png";
import imageSVG from "../../../../styles/assets/media/image.svg";
import imageWEBP from "../../../../styles/assets/media/image_png.webp";

export default function Images() {
    return (
        <div className="media">
            <ImageItem
                img={imageJPEG}
                title=".JPEG"
                pos1="COMPATIBILITY"
                pos2="FILE SIZE"
                pos3="COMPRESSION"
                neg1="FLEXIBILITY"
                desc="The files are greatly reduced in size by compression, which greatly reduces website loading times. JPEG is used on many photos and images."
            />

            <ImageItem
                img={imagePNG}
                title=".PNG"
                pos1="PERFORMANCE"
                pos2="TRANSPARENCY"
                pos3="COMPRESSION"
                neg1="ANIMATION SUPPORT"
                desc="The image quality is stunning, which leads to significantly longer loading times. PNG is therefore only used for images that cannot be changed in quality. PNGs also support an alpha channel, which allows transparent areas."
            />

            <ImageItem
                img={imageWEBP}
                title=".WEBP"
                pos1="FILE SIZE"
                pos2="TRANSPARENCY"
                pos3="COMPRESSION"
                neg1="COMPATIBILITY"
                desc="WebP is a newer image format developed by Google. It offers a high compression with a good quality. The loading times are very short and it requires little memory."
            />

            <ImageItem
                img={imageSVG}
                title=".SVG"
                pos1="QUALITY"
                pos2="FLEXIBILITY"
                pos3="SCALABILITY"
                neg1="FILE SIZE"
                desc="SVG is a format for vector graphics. Unlike raster images, the individual paths are stored instead of the content of pixels in the image. This massively reduces the file size."
            />
        </div>


    );
}
